import { permissions } from '@mpk/shared/domain';

/**
 * Permission for the clinic catalogs
 */
enum ClinicCatalogsPermissionsEnum {
  None = 0,
  /**
   * Clinic catalogs in catalog management can be viewed and managed
   */
  ManageClinicCatalogs = 1,
}

export const ClinicCatalogsPermissions = permissions(ClinicCatalogsPermissionsEnum, {
  featureId: 'ClinicCatalogs',
});
